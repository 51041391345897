import Dialog from 'quasar/src/plugins/Dialog.js';;
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete.vue";

export default {
  data() {
    return {};
  },
  methods: {
    promptStopAccount(account) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Delivery Stop",
            subHeader: account.name + " - " + account.id,
            message:
              "You're about to stop delivery on this account. As a result, it will stop delivering ads immediately. You can resume delivery on this account at any time. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Stop Delivery"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("advertiserAccounts/POST_REQUEST", {
              endpoint: "pause",
              params: { id: account.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptResumeAccount(account) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Delivery Resume",
            subHeader: account.name + " - " + account.id,
            message:
              "You're about to resume delivery on this account. As a result, it will begin delivering ads immediately. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Resume Delivery"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("advertiserAccounts/POST_REQUEST", {
              endpoint: "activate",
              params: { id: account.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptDeleteAccount(account) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModalDelete,
          componentProps: {
            descriptor: account.name + " - " + account.id
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("advertiserAccounts/POST_REQUEST", {
              endpoint: "delete",
              params: { id: account.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("delete", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("delete", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    }
  }
};
